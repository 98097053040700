
import axios from '@/plugins/axios'

export const caseStatuses = {
  todo: {
    color: 'primary',
    textColor: 'white',
    text: 'To do',
    value: 'To do',
    icon: 'mdi-dots-horizontal-circle-outline'
  },
  inprogress: {
    color: 'orange',
    textColor: 'white',
    text: 'In progress',
    value: 'In progress',
    icon: 'mdi-progress-wrench'
  },
  done: {
    color: 'teal',
    textColor: 'white',
    text: 'Done',
    value: 'Done',
    icon: 'mdi-checkbox-marked-circle-outline'
  }
}

export const parseCaseStatus = (status) => {
  return caseStatuses[status]
}

export const submitFile = (path, file) => {
  const formData = new FormData()
  formData.append('file', file)
  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      resolve(result)
    } catch (e) {
      reject(e)
    }
  })
}
