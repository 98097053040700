import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import TemplateQuestionnaireForm from '@/components/forms/TemplateQuestionnaireForm.vue'

export default {
  components: {
    TemplateQuestionnaireForm
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      settings: state => state.settings.settings,
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases,
      templates: state => state.templates.templates,
      companyTemplates: state => state.companyTemplates.companyTemplates,
      groupTemplates: state => state.groupTemplates.groupTemplates
    }),
    actualCaseData () {
      if (!this.cases || !this.companyCases || !this.groupCases) return []
      const _currentCase =
      this.cases.find(c => c._id === this.$route.params._id) ||
      this.companyCases.find(c => c._id === this.$route.params._id) ||
      this.groupCases.find(c => c._id === this.$route.params._id)
      return _currentCase
    },
    workbench () {
      return this.actualCaseData ? this.actualCaseData.workbenches.find(w => w._id === this.$route.params.workbenchId) : null
    },
    template () {
      if (this.workbench) return this.workbench.templates.find(t => t._id === this.$route.params.templateId ? this.$route.params.templateId : this.$route.params._id) || null
      else {
        return this.templates.find(t => t._id === this.$route.params._id) ||
        this.companyTemplates.find(ct => ct._id === this.$route.params._id) ||
        this.groupTemplates.find(gt => gt._id === this.$route.params._id)
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCase: 'cases/fetchCase',
      updateCase: 'cases/updateCase',
      updateCaseWorkbenchTemplate: 'cases/updateCaseWorkbenchTemplate',
      uploadDocument: 'documents/uploadDocument',
      updateQuestionnaireInCase: 'questionnaire/updateQuestionnaireInCase',
      addToast: 'toasts/addToast'
    }),
    async updateActualTemplateName (template, type) {
      const _id = this.actualCaseData._id
      const workbenchId = this.workbench._id
      const templateId = template._id
      let res
      if (type && type === 'from editor') {
        res = template.name
      } else {
        res = await this.$dialog.prompt({
          title: this.$t('actions|change_template_name'),
          text: this.$t('actions|enter_new_name'),
          textField: {
            // Any addtional props/attrs that will be binded to v-text-field component
            type: 'text',
            outlined: true,
            dense: true,
            class: 'mt-6'
          },
          actions: [
            {
              text: this.$t('actions|submit'), color: 'primary'
            }
          ]
        })
      }
      if (!res) return
      const payload = {
        newTemplateName: res
      }
      try {
        await this.updateCaseWorkbenchTemplate({ _id, workbenchId, templateId, payload })
        this.addToast({
          title: this.$t('templates|name_change_successful'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (error) {
        console.error('error', error)
        this.addToast({
          title: this.$t('templates|update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async addNewQuestionnaireFieldInCase (newField) {
      try {
        const payload = {
          field: newField,
          addNewField: true
        }
        const _id = this.template.questionnaire._id
        const caseId = this.actualCaseData._id
        const workbenchId = this.workbench._id
        const templateId = this.template._id
        await this.updateQuestionnaireInCase({ _id, caseId, workbenchId, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|questionnaire_update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async addNewFieldsInCaseQuestionnaire (newFields) {
      try {
        const payload = {
          fields: newFields,
          addNewMultipleFields: true
        }
        const _id = this.template.questionnaire._id
        const caseId = this.actualCaseData._id
        const workbenchId = this.workbench._id
        const templateId = this.template._id
        await this.updateQuestionnaireInCase({ _id, caseId, workbenchId, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|questionnaire_update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async updateQuestionnaireFieldsOrder (actualFields) {
      try {
        const payload = {
          fields: actualFields,
          newFieldsOrder: true
        }
        const _id = this.template.questionnaire._id
        const caseId = this.actualCaseData._id
        const workbenchId = this.workbench._id
        const templateId = this.template._id
        await this.updateQuestionnaireInCase({ _id, caseId, workbenchId, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|questionnaire_update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async updateCaseQuestionnaireField (field) {
      try {
        const payload = {
          field: field,
          updateFieldContent: true
        }
        const _id = this.template.questionnaire._id
        const caseId = this.actualCaseData._id
        const workbenchId = this.workbench._id
        const templateId = this.template._id
        await this.updateQuestionnaireInCase({ _id, caseId, workbenchId, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|questionnaire_update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async deleteFieldInCaseQuestionnaire (field) {
      try {
        const payload = {
          fieldToBeDeleted: field.fieldToBeDeleted,
          deleteFieldFromQuestionnaire: true
        }
        const _id = this.template.questionnaire._id
        const caseId = this.actualCaseData._id
        const workbenchId = this.workbench._id
        const templateId = this.template._id
        await this.updateQuestionnaireInCase({ _id, caseId, workbenchId, templateId, payload })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|questionnaire_update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async saveTemplate ({ payload }) {
      try {
        await this.updateCaseWorkbenchTemplate({
          _id: this.actualCaseData._id,
          workbenchId: this.workbench._id,
          templateId: this.template._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_template_updated'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_template_update_failed'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async uploadDocumentAction (document) {
      const _id = this.account._id
      let proId
      this.actualCaseData.sharedWith.accounts.filter((acc) => {
        proId = this.account.myProfessionals.accounts.find(a => a._id === acc._id)
        return proId
      })
      const payload = {
        language: document.language,
        file: document.file,
        caseId: this.actualCaseData._id,
        clientId: this.actualCaseData.client._id,
        fromQuestionairre: true,
        professionalId: proId._id
      }
      return this.uploadDocument({ _id, payload })
    },
    async updateQuestionnaire (questionairre, issuer) {
      const filesToUpload = questionairre.fields.filter((f) => {
        return f.fieldType === 'file-input'
      })
      const documents = []
      if (filesToUpload.length) {
        for (let i = 0; i < filesToUpload.length; i++) {
          const fileData = await this.uploadDocumentAction(filesToUpload[i].value[0])
          documents.push(fileData.data._id)
        }
      }
      const payloadFields = questionairre.fields.filter((f) => {
        return f.fieldType !== 'file-input'
      })
      await this.$axios.post(`/updateQuestionnaire/${questionairre._id}`, {
        fields: payloadFields,
        documents
      })
      await this.fetchCase(this.actualCaseData._id)
    },
    async shareTemplate (questionnaire) {
      try {
        const payload = {
          sharedWithClient: true,
          caseId: this.actualCaseData._id
        }
        const updatedQuestionnaire = await this.$axios.post(`/updateSharedWithClientQuestionnaire/${questionnaire._id}`, { payload })
        this.template.questionnaire = updatedQuestionnaire.data.data
        this.addToast({
          title: this.$t('message|questionnaire_sent_to_client'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (error) {
        this.addToast({
          title: this.$t('error|sharing_questionnaire'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async stopSharingQuestionnaire (questionnaire) {
      try {
        const payload = {
          sharedWithClient: false,
          caseId: this.actualCaseData._id
        }
        const updatedQuestionnaire = await this.$axios.post(`/updateSharedWithClientQuestionnaire/${questionnaire._id}`, { payload })
        this.template.questionnaire = updatedQuestionnaire.data.data
        this.addToast({
          title: this.$t('message|questionnaire_successfully_unshared'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (error) {
        this.addToast({
          title: this.$t('error|unsharing_questionnaire'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    removeComments () {
      EventBus.$emit('remove-comments', {})
    },
    closeTemp () {
      const actualSelectedWorkbenchIndex = localStorage.getItem('actual-case-workbench') || 0
      this.$router.push({ path: `/cases/${this.actualCaseData._id}/${this.actualCaseData.workbenches[actualSelectedWorkbenchIndex]._id}` })
      this.removeComments()
    }
  }
}
