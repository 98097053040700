<template>
  <v-card
    height="100%"
  >
    <v-toolbar
      flat
      width="100%"
      class="toolbar"
    >
      <v-btn
        icon
        rounded
        @click.stop="$emit('close')"
      >
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ $t('actions|add_document') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-stepper
      v-if="fromCaseView"
      v-model="stepper"
      vertical
      height="100%"
      class="pa-0 ma-0"
    >
      <v-stepper-step
        step="1"
        complete
      >
        {{ $t('actions|choose_source') }}
        <small
          v-if="stepper > 1"
          class="mt-2"
        >
          {{ selectedItem === 'Documents' ? $t('documents|available_documents') : $t('actions|upload') }}
        </small>
      </v-stepper-step>

      <v-stepper-content
        step="1"
        class="text-center"
      >
        <v-radio-group v-model="selectedItem">
          <v-row
            class="text-center"
          >
            <v-col
              cols="12"
            >
              <v-card
                class="d-inline-flex align-center pa-2 my-2"
                outlined
                rounded
                flat
                min-height="40"
                width="270"
              >
                <v-row
                  no-gutters
                >
                  <v-radio
                    :label="$t('actions|upload_from_computer')"
                    color="primary"
                    value="upload"
                  />
                </v-row>
              </v-card>

              <v-card
                class="d-inline-flex align-center pa-2 my-2"
                outlined
                rounded
                flat
                min-height="40"
                width="270"
              >
                <v-row
                  no-gutters
                >
                  <v-radio
                    :label="$t('documents|available_documents')"
                    color="primary"
                    value="Documents"
                  />
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-stepper-content>

      <v-stepper-step
        step="2"
        complete
      >
        {{ $t('actions|select_document') }}
      </v-stepper-step>

      <v-stepper-content
        step="2"
      >
        <div
          v-if="fromAvailableDocuments"
          class="pa-0"
        >
          <v-select
            v-if="userRole && userRole !== 'singleAcc'"
            v-model="selectedSource"
            :items="sources"
            :label="$t('actions|choose_section')"
            dense
            multiple
            class="mt-4"
          />
          <document-list
            :documents="filteredPersonalDocuments"
            :company-documents="filteredCompanyDocuments"
            :group-documents="filteredGroupDocuments"
            :personal-folders="personalFolders"
            :company-folders="companyFolders"
            :group-folders="groupFolders"
            :list-group="selectedDocuments"
            :list-group-company="selectedCompanyDocuments"
            :selected-group-documents="selectedGroupDocuments"
            :toolbar="{
              hasClose: false
            }"
            :from-case-view="fromCaseView"
            :is-flat="true"
            :selected-source="selectedSource"
            :company="company"
            :available-groups="availableGroups"
            :from-workbench="fromWorkbench"
            class="flex-grow-1 pt-5"
            @updateGroup="updateGroup"
            @updateCompanyDocs="updateSelectedCompanyDocuments"
            @updateGroupDocs="updateSelectedGroupDocuments"
            @search="setInput"
            @toolbarClose="dialog = false"
            @selectedFolderDocuments="onSelectedFolderDocuments"
          >
            <template
              slot="actions"
            >
              <v-btn
                rounded
                outlined
                color="primary"
                small
                class="ml-n5 mt-8"
                @click="getBackInStepper"
              >
                <v-icon class="mr-2">
                  mdi-undo
                </v-icon>
                {{ $t('actions|back') }}
              </v-btn>
              <v-spacer />
              <v-btn
                rounded
                color="primary"
                small
                min-width="150px"
                class="ml-2 mt-8"
                @click="selectDocumentAction"
              >
                {{ $t('actions|submit') }}
              </v-btn>
            </template>
          </document-list>
        </div>
      </v-stepper-content>

      <v-stepper-content
        step="3"
      >
        <file-input
          v-model="innerDocuments"
          :in-case="true"
          file-key="raw"
          class="ml-n4"
        />
        <v-btn
          outlined
          rounded
          small
          color="primary"
          class="mt-3"
          @click="getBackInStepper"
        >
          <v-icon class="mr-2">
            mdi-undo
          </v-icon>
          {{ $t('actions|back') }}
        </v-btn>
        <v-btn
          outlined
          rounded
          small
          color="primary"
          right
          class="mt-3 ml-6"
          :disabled="isLanguageSelected(innerDocuments)"
          @click="uploadDocumentAction"
        >
          <v-icon class="mr-2">
            mdi-arrow-up
          </v-icon>
          {{ $t('actions|upload') }}
        </v-btn>
      </v-stepper-content>
      <v-stepper-step
        step="4"
        complete
      >
        {{ $t('actions|save') }}
      </v-stepper-step>
    </v-stepper>
    <!-- <div v-else>
      <v-select
        v-if="userRole && userRole !== 'singleAcc'"
        v-model="selectedSource"
        :items="sources"
        label="Select source"
        dense
        multiple
        :prepend-inner-icon="icons.select"
        style="height: 80%; font-size: 0.8em"
        class="mx-4 mt-8 mb-n3"
      />
      <document-list
        :documents="filteredPersonalDocuments"
        :company-documents="filteredCompanyDocuments"
        :group-documents="filteredGroupDocuments"
        :list-group="selectedDocuments"
        :list-group-company="selectedCompanyDocuments"
        :selected-group-documents="selectedGroupDocuments"
        :toolbar="{
          hasClose: false
        }"
        :from-case-view="fromCaseView"
        :is-flat="true"
        :selected-source="selectedSource"
        :company="company"
        :available-groups="availableGroups"
        :from-workbench="fromWorkbench"
        class="flex-grow-1 pt-5"
        @updateGroup="updateGroup"
        @updateCompanyDocs="updateSelectedCompanyDocuments"
        @updateGroupDocs="updateSelectedGroupDocuments"
        @search="setInput"
        @toolbarClose="dialog = false"
      >
        <template slot="actions">
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            small
            min-width="150px"
            @click="selectDocumentAction"
          >
            {{ $t('actions|submit') }}
          </v-btn>
        </template>
      </document-list>
    </div> -->
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { mdiSelectPlace } from '@mdi/js'

export default {
  props: {
    personalDocuments: {
      type: Array,
      default: () => ([])
    },
    companyDocuments: {
      type: Array,
      default: () => ([])
    },
    groupDocuments: {
      type: Array,
      default: () => ([])
    },
    personalFolders: {
      type: Array,
      default: () => ([])
    },
    companyFolders: {
      type: Array,
      default: () => ([])
    },
    groupFolders: {
      type: Array,
      default: () => ([])
    },
    fromCaseView: {
      type: Boolean,
      default: false
    },
    actualCase: {
      type: Object,
      default: null
    },
    userRole: {
      type: String,
      default: ''
    },
    company: {
      type: Object,
      default: null
    },
    account: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedDocuments: [],
      selectedCompanyDocuments: [],
      selectedGroupDocuments: [],
      innerDocuments: [],
      selectedFolderDocuments: [],
      searchResult: '',
      selectedSource: [].reverse(),
      icons: {
        select: mdiSelectPlace
      },
      availableGroups: [],
      fromWorkbench: false,
      stepper: 1,
      selectedItem: null,
      fromAvailableDocuments: false,
      activeFolderObject: null
    }
  },
  computed: {
    filteredPersonalDocuments () {
      if (this.searchResult && this.searchResult.length && this.personalDocuments.length) {
        return this.personalDocuments.filter((d) => {
          return d.raw.originalname.toLowerCase().includes(this.searchResult.toLowerCase())
        })
      }
      return this.personalDocuments
    },
    filteredCompanyDocuments () {
      if (this.searchResult && this.searchResult.length && this.companyDocuments.length) {
        return this.companyDocuments.filter((d) => {
          return d.raw.originalname.toLowerCase().includes(this.searchResult.toLowerCase())
        })
      }
      return this.companyDocuments
    },
    filteredGroupDocuments () {
      if (this.searchResult && this.searchResult.length && this.groupDocuments.length) {
        return this.groupDocuments.filter((d) => {
          return d.raw.originalname.toLowerCase().includes(this.searchResult.toLowerCase())
        })
      }
      return this.groupDocuments
    },
    sources () {
      let items = []
      if (this.personalDocuments.length && this.userRole !== 'regular') {
        items.push(this.$t('documents|my_documents'))
      }
      if (this.company) {
        items.push(this.company.companyData.companyName)
        if (this.company.groups) {
          this.company.groups.forEach(group => {
            const isPermitted = group.groupMembers.find(mem => mem._id === this.account._id)
            if (isPermitted) {
              items.push(group.groupName)
            }
          })
        }
      }
      return items
    }
  },
  watch: {
    selectedItem (value) {
      if (value && value === 'upload') {
        this.stepper = 3
      }
      if (value && value === 'Documents') {
        this.stepper = 2
        this.fromAvailableDocuments = true
      }
    }
  },
  methods: {
    ...mapActions({
      uploadDocument: 'documents/uploadDocument',
      updateCase: 'cases/updateCase',
      addToast: 'toasts/addToast'
    }),
    getBackInStepper () {
      this.stepper = 1
      this.selectedItem = null
    },
    updateGroup (index) {
      this.selectedDocuments = index
    },
    updateSelectedCompanyDocuments (index) {
      this.selectedCompanyDocuments = index
    },
    updateSelectedGroupDocuments (index) {
      this.selectedGroupDocuments = index
    },
    isLanguageSelected () {
      let rule = true
      let _array = []
      if (this.innerDocuments && this.innerDocuments.length) {
        for (let i = 0; i < this.innerDocuments.length; i++) {
          const doc = this.innerDocuments[i]
          if (!doc.language.length) _array.push('empty')
          else _array.push(doc.language)
        }
        if (_array.indexOf('empty') !== -1) rule = true
        else rule = false
      }
      return rule
    },
    async selectDocumentAction () {
      let selectedDocuments = []
      this.personalDocuments.filter((doc, index) => {
        if (this.selectedDocuments.includes(index)) {
          selectedDocuments.push(doc)
        }
      })
      this.companyDocuments.filter((doc, index) => {
        if (this.selectedCompanyDocuments.includes(index)) {
          selectedDocuments.push(doc)
        }
      })
      this.groupDocuments.filter((doc, index) => {
        if (this.selectedGroupDocuments.includes(index)) {
          selectedDocuments.push(doc)
        }
      })
      if (this.activeFolderObject) selectedDocuments = this.selectedFolderDocuments
      try {
        await this.updateCase({
          _id: this.actualCase._id,
          payload: {
            payload: {
              documents: selectedDocuments
            }
          }
        })
        this.$emit('close')
        this.addToast({
          title: this.$t('common|added_successfully'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (error) {
        console.error(error)
        this.addToast({
          title: this.$t('error|something_went_wrong'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async uploadDocumentAction () {
      for (let i = 0; i < this.innerDocuments.length; i++) {
        const document = this.innerDocuments[i]
        const _id = this.account._id
        const payload = {
          language: document.language,
          clientId: this.actualCase.client._id,
          file: document.file,
          caseId: this.actualCase._id,
          newInCaseUploadedDocument: true,
          fromClient: this.actualCase.client.clientAccount ? this.actualCase.client.clientAccount._id === this.account._id : false
        }
        try {
          await this.uploadDocument({ _id, payload })
          this.$emit('close')
          this.addToast({
            title: this.$t('cases|successfully_uploaded_document_to_case'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch (error) {
          console.log(error)
          this.addToast({
            title: this.$t('error|something_went_wrong'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    setInput (input) {
      this.searchResult = input
    },
    onSelectedFolderDocuments (selectedDocs, folder) {
      this.selectedFolderDocuments = selectedDocs
      this.activeFolderObject = folder
    }
  }
}
</script>

<style scoped>
.toolbar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 48px;
  z-index: 1;
}
</style>
