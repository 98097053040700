<template>
  <v-container
    v-if="!editWorkbenchesPage && !addDocumentsToCasePage && !addRequiredDocumentsToCasePage && !shareCaseMobilePage"
    fluid
    fill-height
    class="pa-0"
    style="background-color: white;"
  >
    <v-row
      no-gutters
      style="height: 100%;"
    >
      <v-col
        cols="12"
        style="height: 100%"
      >
        <v-toolbar
          flat
          width="100%"
          class="toolbar"
        >
          <v-btn
            icon
            rounded
            @click.stop="$router.back()"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <v-hover
            v-slot="{ hover }"
          >
            <div
              class="mt-n2"
              style="min-width: 100%"
            >
              <input
                v-model="currentCase.caseName"
                class="pl-2 mt-2 rounded input"
                style="white-space:nowrap; text-overflow: ellipsis;"
                :style="hover && !flatFields.includes(currentCase.caseName) ? 'background-color: #eee; text-overflow: initial;' : flatFields.includes(currentCase.caseName) ? 'background-color: #CFD8DC' : ''"
                @focus="flatFields.push(currentCase.caseName)"
                @blur="updateCaseName(currentCase.caseName); flatFields.splice(0, 1)"
              >
            </div>
          </v-hover>
        </v-toolbar>

        <v-list
          expand
          class="white pb-16"
        >
          <v-list-group
            v-for="(item, i) in caseSectionsMobile"
            :key="i"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  style="white-space: normal;"
                >
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              class="px-0"
            >
              <!-- CASE DATA -->
              <v-card
                v-if="item.isCaseData"
                flat
                width="100%"
              >
                <v-card-text
                  class=""
                  style="min-height: 20px"
                >
                  <div class="mt-n2">
                    {{ $t('clients|client') }}:
                    <template v-if="currentCase && currentCase.client && !currentCase.client.realClient">
                      <span v-if="currentCase.client.clientType === 'individual'">{{ currentCase.client.clientData.given_names ? currentCase.client.clientData.given_names : 'not added' }} {{ currentCase.client.clientData.surname ? currentCase.client.clientData.surname : 'not added' }}</span>
                      <span v-if="currentCase.client.clientType === 'company'">{{ currentCase.client.clientData.company_name }}</span>
                    </template>
                    <template v-if="currentCase && currentCase.client && currentCase.client.realClient">
                      <span v-if="currentCase.client.clientType === 'individual'">{{ currentCase.client.clientData.given_names ? currentCase.client.clientData.given_names : 'not added' }} {{ currentCase.client.clientData.surname ? currentCase.client.clientData.surname : 'not added' }}</span>
                      <span v-if="currentCase.client.clientType === 'company'">{{ currentCase.client.clientData.company_name }}</span>
                    </template>
                    <template v-if="currentCase && !currentCase.client">
                      <v-icon
                        small
                        color="primary"
                        class="ml-1"
                        @click="assignNewClient()"
                      >
                        mdi-account-plus
                      </v-icon>
                    </template>
                  </div>

                  <div>
                    {{ $t('common|date_created') }}: {{ currentCase.createdAt | date }}
                  </div>

                  <div
                    v-if="account.accountType === 'lawyer'"
                  >
                    {{ $t('common|date_updated') }}: {{ currentCase.updatedAt | dateTime }}
                  </div>

                  <div>
                    {{ $t('common|created_by') }}: {{ currentCase.createdBy.email }}
                  </div>

                  <CaseProgress
                    :status="currentCase.status"
                    :current-case="currentCase"
                    :cols-progress-bar="8"
                    :cols-title="4"
                    :account="account"
                    @update-progress="updateProgress"
                    @open-info-progress-dialog="openInfoProgressDialog"
                  />
                </v-card-text>
              </v-card>

              <!-- CASE PARTICIPANTS -->
              <v-card
                v-if="item.isParticipants"
                flat
                class="ma-0"
                :width="$vuetify.breakpoint.width"
              >
                <v-card-text class="text-center">
                  <div
                    v-for="(participant, index) in sharedWithMembers"
                    :key="index"
                    class="mx-auto my-2"
                  >
                    <v-chip
                      outlined
                      small
                    >
                      <v-avatar
                        size="18px"
                        class="ml-n3 mr-1"
                      >
                        <img
                          v-if="participant && participant.avatar"
                          alt="Avatar"
                          :src="`${cfg.baseURL}downloadAvatar/${participant._id}/${participant.avatar.raw.filename}`"
                        >
                        <v-avatar
                          v-else
                        >
                          <v-icon
                            v-if="participant.type === 'account'"
                            color="primary"
                            small
                          >
                            mdi-account
                          </v-icon>
                          <v-icon
                            v-if="participant.type === 'company'"
                            color="primary"
                            small
                          >
                            mdi-domain
                          </v-icon>
                          <v-icon
                            v-if="participant.type === 'group'"
                            color="primary"
                            small
                          >
                            mdi-account-group
                          </v-icon>
                        </v-avatar>
                      </v-avatar>
                      <span
                        class="text-truncate"
                        style="font-size: 12px;"
                      >
                        {{ participant.participantName }}
                      </span>
                    </v-chip>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-chip
                    v-if="account.accountType === 'lawyer'"
                    rounded
                    color="primary"
                    small
                    class="ml-n2 mt-n2"
                    @click="openShareCaseDialog"
                  >
                    <v-icon
                      left
                      small
                      style="position: absolute; left 3px;"
                    >
                      mdi-plus
                    </v-icon>
                    <span class="ml-3">{{ $t('actions|add_new') }}</span>
                  </v-chip>

                  <v-chip
                    v-if="account.accountType === 'lawyer'"
                    rounded
                    color="primary"
                    small
                    class="ml-2 mt-n2"
                    :disabled="currentCase.sharedWith.accounts.length + currentCase.sharedWith.company.length + currentCase.sharedWith.groups.length === 1"
                    @click="removeCase(currentCase)"
                  >
                    <v-icon
                      left
                      small
                      style="position: absolute; left 6px;"
                    >
                      mdi-minus
                    </v-icon>
                    <span class="ml-3">{{ $t('actions|delete') }}</span>
                  </v-chip>
                  <v-spacer />
                </v-card-actions>
              </v-card>

              <!-- CASE DOCUMENTS -->
              <v-card
                v-if="item.isCaseDocuments"
                flat
                width="100%"
              >
                <v-card-text>
                  <div
                    v-for="(doc, index) in _documents"
                    :key="index"
                    class="list-group-item"
                  >
                    <DocumentListItemCasePanelRight
                      :document="doc"
                      :index="index"
                      :in-case="true"
                      @open-doc="openDocument(doc)"
                      @deleteAction="removeCaseDocument(doc)"
                    />
                  </div>
                </v-card-text>
                <v-card-text
                  v-if="!_documents.length"
                  class="text-center"
                >
                  {{ $t('expressions|no_available_documents') }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-chip
                    color="primary"
                    rounded
                    small
                    class="mt-4"
                    style="width: 40%;"
                    @click.stop="assignCaseDocument"
                  >
                    <v-icon
                      small
                      class="mr-1"
                    >
                      mdi-plus
                    </v-icon>
                    {{ $t('actions|add_new') }}
                  </v-chip>
                  <v-spacer />
                </v-card-actions>
              </v-card>

              <!-- REQUIRED CASE DOCUMENTS -->
              <v-card
                v-if="item.isRequiredCaseDocuments"
                flat
                width="100%"
              >
                <v-card-text>
                  <div
                    v-for="(suggestion, index) in currentCase.requiredCaseDocuments"
                    :key="index"
                  >
                    <v-card
                      flat
                      rounded
                      outlined
                      min-height="60px"
                      class="my-4 mx-2 d-flex rounded-lg"
                    >
                      <v-row
                        no-gutters
                      >
                        <v-col
                          cols="2"
                        >
                          <v-card
                            class="rounded-r-0"
                            flat
                            height="100%"
                            color="lightBackground"
                            :style="`border-top-left-radius: 10px; border-bottom-left-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  class="handle-icon centered-icon"
                                  style="cursor: grab;"
                                  size="34"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.drag }}
                                </v-icon>
                              </template>
                              <span>{{ $t('actions|drag_document_to_workbench') }}</span>
                            </v-tooltip>
                          </v-card>
                        </v-col>
                        <v-col cols="1">
                          <v-icon
                            color="primary"
                            class="ml-2 mt-4"
                          >
                            mdi-file-outline
                          </v-icon>
                        </v-col>
                        <v-col
                          cols="7"
                        >
                          <v-card
                            flat
                            class="rounded-l-0"
                          >
                            <div
                              class="grey--text text--darken-2 ml-3 mt-2"
                              style="font-size: 14px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                            >
                              {{ $t('documents|required_document') }}
                            </div>
                            <div
                              class="primary--text ml-3 mt-1"
                              :style="`font-size: 14px; text-overflow: ellipsis; overflow: hidden; white-space: normal;`"
                            >
                              {{ translateFileInputField(suggestion) }}
                            </div>
                          </v-card>
                        </v-col>
                        <v-col
                          cols="2"
                        >
                          <v-card
                            class="rounded-l-0"
                            flat
                            height="100%"
                            color="lightBackground"
                            :style="`border-top-right-radius: 10px; border-bottom-right-radius: 10px; border: 1px solid ${$vuetify.theme.themes.light.background};`"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  :color="checkIfSuggestionTypeAvailableInDocuments(suggestion) ? 'success' : 'grey'"
                                  v-bind="attrs"
                                  size="20"
                                  class="ml-1 mt-1"
                                  v-on="on"
                                >
                                  mdi-check-circle
                                </v-icon>
                              </template>
                              <span>
                                {{ checkIfSuggestionTypeAvailableInDocuments(suggestion) ? $t('expressions|document_type_available_in_wb') : $t('expressions|document_type_not_uploaded') }}
                              </span>
                            </v-tooltip>
                            <v-menu
                              offset-y
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  icon
                                  size="20"
                                  class="ml-1 mt-1"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.cog }}
                                </v-icon>
                              </template>
                              <v-list>
                                <v-list-item>
                                  <v-icon
                                    color="primary"
                                    class="mr-2"
                                  >
                                    mdi-link-off
                                  </v-icon>
                                  <v-list-item-title
                                    style="cursor: pointer;"
                                    @click="removeRequiredDocument(suggestion)"
                                  >
                                    {{ $t('actions|remove') }}
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </v-card-text>
                <v-card-text
                  v-if="!currentCase.requiredCaseDocuments.length"
                  class="ml-5 mt-4"
                  style="font-size: 15px"
                >
                  {{ $t('mixed|no_required_workbench_documents') }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-chip
                    color="primary"
                    rounded
                    small
                    class=""
                    style="width: 40%;"
                    @click.stop="assignRequiredCaseDocument"
                  >
                    <v-icon
                      small
                      class="mr-1"
                    >
                      mdi-plus
                    </v-icon>
                    {{ $t('actions|add_new') }}
                  </v-chip>
                  <v-spacer />
                </v-card-actions>
              </v-card>

              <!-- WORKBENCHES -->
              <v-card
                v-if="item.isWorkbenches"
                flat
                width="100%"
                class="pa-0"
              >
                <v-card-text class="pa-0">
                  <div
                    v-for="workbench in currentCase.workbenches"
                    :key="workbench._id"
                    style="min-height: 40px;"
                    class="my-2"
                    @click="$router.push(`/mobile-case/${currentCase._id}/${workbench._id}`)"
                  >
                    <span
                      class="ml-4"
                      style="color: black;"
                    >
                      {{ workbench.name }}
                    </span>
                    <v-icon style="position: absolute; right: 15px;">
                      {{ icons.next }}
                    </v-icon>
                  </div>
                </v-card-text>
              </v-card>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-col>
    </v-row>

    <!-- TOOLBAR BOTTOM => ACTIONS -->
    <v-toolbar
      flat
      color="primary"
      class="bottom-element-case-view"
    >
      <!-- CASE STATUS -->
      <v-menu
        v-model="statusMenu"
        offset-y
        :disabled="account.accountType !== 'lawyer'"
        @click.native.stop.prevent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="account.accountType === 'lawyer'"
            icon
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ getTheRightIcon(currentCase.status) }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="status in caseStatuses"
            :key="status.value"
            :active-class="status.value === currentCase.status ? 'active-item' : ''"
            :class="{active: status.value === currentCase.status}"
            @click.stop="changeStatus(currentCase, status); statusMenu = false"
          >
            <v-icon
              class="ml-n2 mr-2 mt-1"
              size="18"
              :color="status.color"
            >
              {{ status.icon }}
            </v-icon>
            <v-list-item-title>{{ getTranslatedStatus(status.text) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- PIN -->
      <v-btn
        v-if="currentCase.pinned.includes(account._id)"
        icon
        dark
        @click.stop="removePinFromDashboard(currentCase)"
      >
        <v-icon>
          mdi-pin
        </v-icon>
      </v-btn>

      <v-btn
        v-else
        icon
        dark
        @click.stop="pinToDashboard(currentCase)"
      >
        <v-icon>
          mdi-pin-outline
        </v-icon>
      </v-btn>

      <!-- EXPORT CASE -->
      <v-btn
        icon
        dark
        @click="exportCase(currentCase)"
      >
        <v-icon>
          mdi-download
        </v-icon>
      </v-btn>

      <!-- GENERATE CASE MODEL -->
      <v-btn
        icon
        dark
        @click="generateCaseModel"
      >
        <v-icon>
          mdi-briefcase-edit
        </v-icon>
      </v-btn>

      <!-- CASE TIMELINE -->
      <v-btn
        icon
        dark
        @click="openCaseTimeline"
      >
        <v-icon>
          mdi-timeline
        </v-icon>
      </v-btn>

      <!-- WORKBENCH ACTIONS -->
      <v-menu
        v-if="account.accountType === 'lawyer'"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            dark
            v-bind="attrs"
            class="ml-2"
            v-on="on"
          >
            {{ icons.desk }}
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in workbenchMenuItems"
            :key="index"
            @click="triggerAction(item.action)"
          >
            <v-icon
              small
            >
              {{ item.icon }}
            </v-icon>
            <v-list-item-title class="ml-2">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- DELETE CASE -->

      <v-btn
        icon
        dark
        style="position: absolute; right: 10px;"
        @click="getCaseSharedWithLength(currentCase) <= 1 ? deleteCase(currentCase) : removeCase(currentCase)"
      >
        <v-icon>
          mdi-trash-can-outline
        </v-icon>
      </v-btn>
    </v-toolbar>

    <Editor
      v-show="false"
      ref="Editor"
    />
  </v-container>
  <EditCaseWorkbenchesMobile
    v-else-if="editWorkbenchesPage"
    :workbenches="currentCase.workbenches"
    :loading="loadingDeleteWorkbench"
    @update="updateWorkbenches"
    @delete="deleteWorkbench"
    @close="editWorkbenchesPage = false"
  />
  <AddDocumentsToCaseMobile
    v-else-if="addDocumentsToCasePage"
    :personal-documents="filteredDocumentsByUsage"
    :company-documents="forwardCompanyDocuments"
    :group-documents="forwardGroupDocuments"
    :personal-folders="folders"
    :company-folders="companyFolders"
    :group-folders="groupFolders"
    :from-case-view="true"
    :actual-case="currentCase"
    :user-role="verifyUserRole(company, account)"
    :company="company"
    :account="account"
    @close="addDocumentsToCasePage = false"
  />
  <AddRequiredDocumentsToCaseMobile
    v-else-if="addRequiredDocumentsToCasePage"
    :req-document-fields="reqDocumentFields"
    @searching-field="search"
    @languageChange="changeSelectedLanguage"
    @customFieldInputActive="changeCustomFieldInput"
    @close="addRequiredDocumentsToCasePage = false"
    @addDocumentSuggestionsToCase="addDocumentSuggestionsToCase"
    @addCustomField="addCustomField"
  />
  <ShareCaseMobile
    v-else-if="shareCaseMobilePage"
    :current-case="currentCase"
    @close="shareCaseMobilePage = false"
  />
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { caseStatuses } from '@/utils/index'
import { EventBus } from '@/utils/EventBus'
import { uniqBy } from '@/utils/utils'
import { v4 as uuidv4 } from 'uuid'
import { saveAs } from 'file-saver'
import cfg from '@/config'
import JSZip from 'jszip'
import exportTemplate from '@/pages/TemplateEditor/exportDocx.js'
import templateViewMixin from '../../Cases/CaseMixins/templateViewMixin'
import generalMixin from '@/utils/generalMixin'
import CaseCardMixin from '../../Cases/CaseMixins/CaseCardMixin'
import CaseProgress from '../../Cases/CaseProgress.vue'
import DocumentListItemCasePanelRight from '@/components/partials/DocumentListItemCasePanelRight.vue'
import Editor from '@/pages/TemplateEditor/Editor.vue'
import EditCaseWorkbenchesMobile from '../../Mobile/Cases/EditCaseWorkbenchesMobile.vue'
import AddDocumentsToCaseMobile from './AddDocumentsToCaseMobile.vue'
import AddRequiredDocumentsToCaseMobile from './AddRequiredDocumentsToCaseMobile.vue'
import ShareCaseMobile from './ShareCaseMobile.vue'

// import InfoProgressDialog from '@/components/dialogs/InfoProgressDialog.vue'
// import draggable from 'vuedraggable'
// import FileView from '@/components/dialogs/FileView.vue'
// import CommentCard from '@/pages/TemplateEditor/CommentCard.vue'
// import ShareCase from '@/components/dialogs/ShareCase.vue'
// import QuestionnaireSuggestionsPanel from '@/components/forms/QuestionnaireFieldEdit/QuestionnaireSuggestionsPanel.vue'
// import FillingAssistentPanel from '../../Cases/FillingAssistentPanel.vue'
// import NewCaseClientDialog from '@/components/dialogs/NewCaseClientDialog.vue'
import {
  mdiTimerSand,
  mdiCheckCircleOutline,
  mdiPlus,
  mdiTrashCanOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiPinOutline,
  mdiFile,
  mdiClose,
  mdiTextBoxSearchOutline,
  mdiCommentOffOutline,
  mdiDatabaseOffOutline,
  mdiUploadOffOutline,
  mdiPencil,
  mdiCog,
  mdiDrag,
  mdiDesk,
  mdiArrowRightThin
} from '@mdi/js'
// import GeneralQuestionnaireFinalForm from '@/components/forms/GeneralQuestionnaireFinalForm.vue'
// import FullQuestionnaireForm from './FullQuestionnaireForm.vue'
// import GeneralQuestionairreCardCases from './GeneralQuestionairreCardCases.vue'
// import GeneralQuestionnaireForm from '../../components/forms/GeneralQuestionnaireForm.vue'

export default {
  components: {
    CaseProgress,
    DocumentListItemCasePanelRight,
    Editor,
    EditCaseWorkbenchesMobile,
    AddDocumentsToCaseMobile,
    AddRequiredDocumentsToCaseMobile,
    ShareCaseMobile
    // InfoProgressDialog
    // FullQuestionnaireForm,
    // GeneralQuestionairreCardCases,
    // GeneralQuestionnaireFinalForm,
    // GeneralQuestionnaireForm,
    // FileView,
    // CommentCard,
    // ShareCase,
    // NewCaseClientDialog,
    // QuestionnaireSuggestionsPanel,
    // FillingAssistentPanel,
    // draggable,
  },
  mixins: [CaseCardMixin, templateViewMixin, generalMixin],
  data () {
    return {
      workbenchMenuItems: [
        {
          text: this.$t('actions|edit_workbenches'),
          icon: mdiPencil,
          action: 'editWorkbenchesPage'
        },
        {
          text: this.$t('actions|add_workbench'),
          icon: mdiPlus,
          action: 'addWorkbench'
        }
      ],
      icons: {
        trash: mdiTrashCanOutline,
        arrowDown: mdiChevronDown,
        arrowUp: mdiChevronUp,
        pin: mdiPinOutline,
        file: mdiFile,
        close: mdiClose,
        textBox: mdiTextBoxSearchOutline,
        notInUseInQuestionairre: mdiCommentOffOutline,
        emptyDatabase: mdiDatabaseOffOutline,
        notUploaded: mdiUploadOffOutline,
        check: mdiCheckCircleOutline,
        progressQuestion: mdiTimerSand,
        cog: mdiCog,
        drag: mdiDrag,
        desk: mdiDesk,
        next: mdiArrowRightThin
      },
      loading: true,
      loadingDeleteWorkbench: false,
      selectedWbTab: localStorage.getItem('actual-case-workbench') || null,
      actualTabTemplateViewCases: 0,
      statusMenu: false,
      caseStatuses,
      rightPanelExpand: 'caseDocuments',
      templateIsSeleted: false,
      foundDocuments: [],
      accountMembers: [],
      flatFields: [],
      searchInput: '',
      searchInputGeneralSearch: '',
      searchText: null,
      documentsPanelLoading: false,
      elevationEffect: false,
      commentId: '',
      editWorkbenchesPage: false,
      fullQuestionnaireFormDialog: false,
      generalQDialog: false,
      caseModelData: null,
      userRole: null,
      cfg,
      componentKey: 1,
      templateSuggestionsComponentKey: 1,
      addNewRequiredDocDialog: false,
      activeReqDocuments: [],
      customField: false,
      customFieldInput: null,
      selectedLanguage: null,
      dragAreaRequiredDocsCase: false,
      dragAreaDocsCase: false,
      addDocumentsToCasePage: false,
      addRequiredDocumentsToCasePage: false,
      shareCaseMobilePage: false
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      cases: state => state.cases.cases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases,
      account: state => state.account.account,
      company: state => state.company.company,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments,
      folders: state => state.documents.folders,
      companyFolders: state => state.companyDocuments.companyFolders,
      groupFolders: state => state.groupDocuments.groupFolders,
      actualCommentsEditorStorage: state => state.editorStore.actualCommentsEditorStorage,
      actualSuggestionsEditorStorage: state => state.editorStore.actualSuggestionsEditorStorage
    }),
    caseSectionsMobile () {
      return [
        {
          action: 'mdi-briefcase-edit',
          title: this.$t('cases|case_data'),
          active: false,
          isCaseData: true,
          isParticipants: false,
          isCaseDocuments: false,
          isRequiredCaseDocuments: false,
          isWorkbenches: false
        },
        {
          action: 'mdi-account-group',
          title: this.$t('cases|participants'),
          active: false,
          isCaseData: false,
          isParticipants: true,
          isCaseDocuments: false,
          isRequiredCaseDocuments: false,
          isWorkbenches: false
        },
        {
          action: 'mdi-file',
          title: this.$t('mixed|case_documents'),
          active: false,
          isCaseData: false,
          isParticipants: false,
          isCaseDocuments: true,
          isRequiredCaseDocuments: false,
          isWorkbenches: false
        },
        {
          action: 'mdi-file-outline',
          title: this.$t('mixed|required_case_documents'),
          active: false,
          isCaseData: false,
          isParticipants: false,
          isCaseDocuments: false,
          isRequiredCaseDocuments: true,
          isWorkbenches: false
        },
        {
          action: this.icons.desk,
          title: this.$t('case|workbenches'),
          active: false,
          isCaseData: false,
          isParticipants: false,
          isCaseDocuments: false,
          isRequiredCaseDocuments: false,
          isWorkbenches: true
        }
      ]
    },
    currentCase () {
      if (!this.cases || !this.companyCases || !this.groupCases) return []
      const _currentCase = this.cases.find(c => c._id === this.$route.params._id) || this.companyCases.find(c => c._id === this.$route.params._id) || this.groupCases.find(c => c._id === this.$route.params._id)
      return _currentCase
    },
    actualWorkbench () {
      return this.currentCase.workbenches.find(w => w._id === this.$route.params.workbenchId)
    },
    actualCaseViewRoute () {
      let route
      if (this.actualWorkbench) {
        route = `/cases/${this.$route.params._id}/${this.actualWorkbench._id}`
      }
      return route
    },
    actualTemplate () {
      let template
      if (this.actualWorkbench) {
        template = this.actualWorkbench.templates.find(t => t._id === this.$route.params.templateId)
      }
      return template
    },
    actualLanguage () {
      return localStorage.getItem('preferedLanguage')
    },
    questionnaireFields () {
      return this.actualTemplate.questionnaire.fields.filter(field => field.fieldType !== 'file-input')
    },
    actualTemplateAndQuestionairreSuggestions () {
      let suggestions = []
      let finalFilter = []
      if (this.actualSuggestionsEditorStorage.length) {
        this.actualSuggestionsEditorStorage.forEach(element => {
          suggestions.push(element.attrs)
        })
      }
      if (this.actualTemplate.questionnaire.fields && this.actualTemplate.questionnaire.fields.length) {
        this.actualTemplate.questionnaire.fields.forEach(field => {
          if (field.fieldType !== 'file-input') {
            suggestions.push(field)
          }
        })
      }
      if (suggestions.length) {
        finalFilter = uniqBy(suggestions, 'id')
      }
      return finalFilter
    },
    requestedDocumentsInQuestionairre () {
      let requestedDocs = []
      if (this.actualTemplate.questionnaire.fields && this.actualTemplate.questionnaire.fields.length) {
        this.actualTemplate.questionnaire.fields.forEach(field => {
          if (field.fieldType === 'file-input') {
            requestedDocs.push(field)
          }
        })
      }
      return requestedDocs
    },
    allCurrentTemplateData () {
      return this.currentCase.workbenches.reduce((acc, w) => {
        acc.push(
          ...w.templates
        )
        return acc
      }, [])
    },
    _documents () {
      if (this.searchInput === '' || !this.searchInput) return this.currentCase.documents ? this.currentCase.documents : []
      if (this.searchInput && this.searchInput.length) {
        return this.currentCase.documents.filter((doc) => {
          return doc.raw.originalname.toLowerCase().includes(this.searchInput.toLowerCase())
        })
      }
      return this.currentCase.documents
    },
    clientDocuments () {
      let docsToReturn = []
      this.currentCase.documents.forEach(doc => {
        const mactchId = doc.sharedWith.accounts.find(id => id === this.currentCase.client.clientAccount._id)
        if (mactchId) {
          docsToReturn.push(doc)
        }
      })
      if (this.searchInput && this.searchInput.length) {
        return docsToReturn.filter((doc) => {
          return doc.raw.originalname.toLowerCase().includes(this.searchInput.toLowerCase())
        })
      }
      return docsToReturn
    },
    filteredGroupDocuments () {
      let groupDocs = []
      const role = this.verifyUserRole(this.company, this.account)
      if (role && role === 'singleAcc') return
      if (this.company) {
        this.company.groups.forEach(group => {
          const index = group.groupMembers.findIndex(m => m._id === this.account._id)
          if (index !== -1) {
            this.groupDocuments.forEach(doc => {
              doc.sharedWith.groups.find(g => g._id === group._id)
              if (doc) {
                groupDocs.push(doc)
              }
            })
          }
        })
      }
      return groupDocs
    },
    filteredDocumentsByUsage () {
      return this.documents.filter((doc) => {
        const alreadyInUse = this.currentCase.documents.some(d => d._id === doc._id)
        return !alreadyInUse
      })
    },
    forwardCompanyDocuments () {
      return this.companyDocuments.filter((doc) => {
        const alreadyInUse = this.currentCase.documents.some(d => d._id === doc._id)
        return !alreadyInUse
      })
    },
    forwardGroupDocuments () {
      return this.filteredGroupDocuments.filter((doc) => {
        const alreadyInUse = this.currentCase.documents.some(d => d._id === doc._id)
        return !alreadyInUse
      })
    },
    sharedWithMembers () {
      const arr = []
      if (this.currentCase.sharedWith.accounts.length) {
        this.currentCase.sharedWith.accounts.forEach((acc) => {
          if (acc.accountType === 'lawyer') {
            const participant = {
              _id: acc._id,
              participantName: acc.accountData.firstName && acc.accountData.lastName ? acc.accountData.firstName + ' ' + acc.accountData.lastName : acc.accountName,
              avatar: acc.avatar,
              type: 'account'
            }
            arr.push(participant)
          }
          if (acc.accountType === 'individual') {
            const participant = {
              _id: acc._id,
              participantName: acc.accountData.firstName && acc.accountData.lastName ? acc.accountData.firstName + ' ' + acc.accountData.lastName : acc.accountName,
              avatar: acc.avatar,
              type: 'account'
            }
            arr.push(participant)
          }
          if (acc.accountType === 'company') {
            const participant = {
              _id: acc._id,
              participantName: acc.accountData.companyName && acc.accountData.companyType ? acc.accountData.companyName + ' ' + acc.accountData.companyType : acc.accountData.companyName,
              avatar: acc.avatar,
              type: 'account'
            }
            arr.push(participant)
          }
        })
      }
      if (this.currentCase.sharedWith.company.length) {
        this.currentCase.sharedWith.company.forEach((c) => {
          const participant = {
            participantName: c.companyData.companyName,
            avatar: null,
            type: 'company'
          }
          arr.push(participant)
        })
      }
      if (this.currentCase.sharedWith.groups.length) {
        this.currentCase.sharedWith.groups.forEach((g) => {
          const participant = {
            participantName: g.groupName,
            avatar: null,
            type: 'group'
          }
          arr.push(participant)
        })
      }
      return arr
    },
    reqDocumentFields () {
      if (!this.settings || !this.settings.fields) return []
      const arr = Object.keys(this.settings.fields).map((key) => {
        return {
          id: uuidv4(),
          name: this.selectedLanguage ? this.settings.fields[key].label[this.selectedLanguage] : this.settings.fields[key].label[this.actualLanguage],
          fieldType: this.settings.fields[key].type,
          fieldKey: key,
          key: key
        }
      })
      let onlyDocTypeArr = arr.filter(field => field.fieldType === 'file-input' && field.name !== 'Custom')
      let filteredArr = onlyDocTypeArr
      const filterItems = (arr, query) => {
        return arr.filter((el) => {
          if (el.name && Array.isArray(el.name)) {
            el.name.forEach(_name => {
              return _name.toLowerCase().indexOf(query.toLowerCase()) !== -1
            })
          } else {
            if (el.name) return el.name.toLowerCase().includes(query.toLowerCase())
          }
        })
      }
      if (this.searchText && this.searchText.length) {
        filteredArr = filterItems(onlyDocTypeArr, this.searchText)
      }
      return filteredArr
    },
    disabledRule () {
      if (!this.customField && !this.activeReqDocuments.length) {
        return true
      } else if (this.customField && this.customFieldInput === null) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    // $route (to, from) {
    //   if (this.actualCaseViewRoute && to.fullPath === this.actualCaseViewRoute) {
    //     this.openLeftDrawerMenu()
    //   }
    //   if (this.$route.params.templateId && this.account.accountType === 'lawyer') {
    //     this.closeLeftDrawerMenu()
    //   }
    // }
  },
  updated () {
    // if (this.$route.params.templateId && this.account.accountType === 'lawyer') {
    //   this.closeLeftDrawerMenu()
    // }
    // if (!this.$route.params.workbenchId && this.currentCase && this.currentCase.workbenches && this.currentCase.workbenches[0]) {
    //   const actualWbTabIndex = localStorage.getItem('actual-case-workbench')
    //   if (this.currentCase.workbenches[actualWbTabIndex]) {
    //     this.$router.push(`/cases/${this.$route.params._id}/${this.currentCase.workbenches[actualWbTabIndex]._id}`)
    //   } else {
    //     this.$router.push(`/cases/${this.$route.params._id}/${this.currentCase.workbenches[0]._id}`)
    //   }
    // }
  },
  async created () {
    // window.history.replaceState(window.history.state, '', '/cases')
    // await this.fetchCase(this.$route.params._id)
    // // if (process.env.NODE_ENV === 'development') window.location.assign(`${'localhost:8080'}${this.$route.fullPath}`)
    // // else window.location.assign(`${this.$route.fullPath}`)
    // if (this.currentCase && this.currentCase.workbenches && this.currentCase.workbenches[0]) {
    //   const actualWbTabIndex = localStorage.getItem('actual-case-workbench')
    //   if (this.currentCase.workbenches[actualWbTabIndex] && !this.$route.params.workbenchId) {
    //     this.$router.push(`/cases/${this.$route.params._id}/${this.currentCase.workbenches[actualWbTabIndex]._id}`)
    //   } else {
    //     if (this.$route.params.workbenchId && this.$route.params.templateId) this.$router.push(`/cases/${this.$route.params._id}/${this.$route.params.workbenchId}/${this.$route.params.templateId}`).catch(() => {})
    //     else if (this.$route.params.workbenchId && !this.$route.params.templateId) this.$router.push(`/cases/${this.$route.params._id}/${this.$route.params.workbenchId }`).catch(() => {})
    //     else this.$router.push(`/cases/${this.$route.params._id}/${this.currentCase.workbenches[0]._id}`)
    //   }
    // }
    EventBus.$on('update-component-key', this.onUpdateComponentKey)
    EventBus.$on('actual-comments-editor', this.onActualCommentsEditor)
    EventBus.$on('select-comment', this.onSelectCommentEvent)
    EventBus.$on('no-comment-selection', this.onNoCommentSelection)
    EventBus.$on('actual-tab-template-view-cases', this.onActualTabTemplateViewCases)
    EventBus.$on('refresh-view', this.refreshView)
    EventBus.$on('refresh-mobile-case', this.onUpdateComponentKey)
    this.$nextTick(() => {
      this.loading = false
    })
  },
  beforeDestroy () {
    EventBus.$off('update-component-key', this.onUpdateComponentKey)
    EventBus.$off('actual-comments-editor', this.onActualCommentsEditor)
    EventBus.$off('select-comment', this.onSelectCommentEvent)
    EventBus.$off('no-comment-selection', this.onNoCommentSelection)
    EventBus.$off('actual-tab-template-view-cases', this.onActualTabTemplateViewCases)
    EventBus.$off('refresh-view', this.refreshView)
    EventBus.$off('refresh-mobile-case', this.onUpdateComponentKey)
    this.openLeftDrawerMenu()
  },
  methods: {
    ...mapActions({
      fetchCase: 'cases/fetchCase',
      updateCase: 'cases/updateCase',
      updateCompanyCase: 'companyCases/updateCompanyCase',
      updateGroupCase: 'groupCases/updateGroupCase',
      updateCaseClient: 'cases/updateCaseClient',
      addCaseWorkbench: 'cases/addCaseWorkbench',
      addCompanyCaseWorkbench: 'companyCases/addCompanyCaseWorkbench',
      addGroupCaseWorkbench: 'groupCases/addGroupCaseWorkbench',
      updateCaseWorkbench: 'cases/updateCaseWorkbench',
      updateCompanyCaseWorkbench: 'companyCases/updateCompanyCaseWorkbench',
      updateGroupCaseWorkbench: 'groupCases/updateGroupCaseWorkbench',
      deleteCaseWorkbenchDocument: 'cases/deleteCaseWorkbenchDocument',
      deleteCompanyCaseWorkbenchDocument: 'companyCases/deleteCompanyCaseWorkbenchDocument',
      deleteGroupCaseWorkbenchDocument: 'groupCases/deleteGroupCaseWorkbenchDocument',
      deleteCaseWorkbench: 'cases/deleteCaseWorkbench',
      deleteCompanyCaseWorkbench: 'companyCases/deleteCompanyCaseWorkbench',
      deleteGroupCaseWorkbench: 'groupCases/deleteGroupCaseWorkbench',
      addCaseWorkbenchTemplate: 'cases/addCaseWorkbenchTemplate',
      deleteCaseWorkbenchTemplate: 'cases/deleteCaseWorkbenchTemplate',
      uploadDocument: 'documents/uploadDocument'
    }),
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    changeSelectedLanguage (lang) {
      this.selectedLanguage = lang
    },
    changeCustomFieldInput (input) {
      this.customFieldInput = input
    },
    setActualWorkbench (idx) {
      localStorage.setItem('actual-case-workbench', idx)
    },
    refreshView () {
      this.templateSuggestionsComponentKey++
    },
    onUpdateComponentKey () {
      this.componentKey++
    },
    openLeftDrawerMenu () {
      this.toggleDrawer(true)
    },
    closeLeftDrawerMenu () {
      this.toggleDrawer(false)
    },
    openShareCaseDialog () {
      this.shareCaseMobilePage = true
    },
    onSearchInput (value) {
      this.searchInput = value
    },
    search (input) {
      this.searchText = input
    },
    onActualTabTemplateViewCases (actualTab) {
      this.actualTabTemplateViewCases = actualTab
    },
    onAddNewDocument () {
      EventBus.$emit('add-new-file-input-suggestion')
    },
    onDragStartToWorkbench () {
      this.dragAreaDocsCase = true
      EventBus.$emit('drag-area-active')
    },
    onDragEndToWorkbench () {
      this.dragAreaDocsCase = false
      EventBus.$emit('drag-area-not-active')
    },
    async onChangeDocumentList (evnt) {
      try {
        await this.updateCase({
          _id: this.currentCase._id,
          payload: {
            payload: {
              movedCaseDocument: true,
              documentId: evnt.moved.element._id,
              newIndex: evnt.moved.newIndex,
              oldIndex: evnt.moved.oldIndex
            }
          }
        })
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('error|moving_document_fail'),
          snackbarColor: 'error',
          color: 'white'
        })
      }
    },
    onDragStart () {
      this.dragAreaRequiredDocsCase = true
      EventBus.$emit('drag-area-required-docs-active')
    },
    onDragEnd () {
      this.dragAreaRequiredDocsCase = false
      EventBus.$emit('drag-area-required-docs-not-active')
    },
    async onDragChange (evnt) {
      try {
        await this.updateCase({
          _id: this.currentCase._id,
          payload: {
            payload: {
              requiredCaseDocumentsDrag: true,
              field: evnt.moved.element,
              newIndex: evnt.moved.newIndex,
              oldIndex: evnt.moved.oldIndex
            }
          }
        })
        this.addNewRequiredDocDialog = false
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|adding_required_document_failed'),
          snackbarColor: 'error',
          color: 'white'
        })
      }
    },
    checkIfSuggestionTypeAvailableInDocuments (suggestion) {
      if (this.actualWorkbench) {
        return this.actualWorkbench.assignedDocuments.find(doc => {
          return doc.info.document_type ? doc.info.document_type.toLowerCase() === suggestion.fieldKey.toLowerCase() : false
        })
      }
    },
    // verifyUserRole () {
    //   if (this.company && this.company.userRoles) {
    //     const regularUser = this.company.userRoles.basic.some(u => u === this.account._id)
    //     const advancedUser = this.company.userRoles.advanced.some(u => u === this.account._id)
    //     const adminUser = this.company.userRoles.admin.some(u => u === this.account._id)
    //     const adminOwnerUser = this.company.userRoles.adminOwner.some(u => u === this.account._id)
    //     if (regularUser) {
    //       this.userRole = 'regular'
    //       return this.userRole
    //     } else if (advancedUser) {
    //       this.userRole = 'advanced'
    //       return this.userRole
    //     } else if (adminUser) {
    //       this.userRole = 'admin'
    //       return this.userRole
    //     } else if (adminOwnerUser) {
    //       this.userRole = 'adminOwner'
    //       return this.userRole
    //     }
    //   } else {
    //     return 'singleAcc'
    //   }
    // },
    onSelectCommentEvent (label, id) {
      let newArray = []
      for (let i = 0; i < this.actualCommentsEditorStorage.length; i++) {
        const comment = this.actualCommentsEditorStorage[i]
        if (comment.attrs.id === id) {
          document.querySelectorAll('#commentText').forEach(ele => {
            newArray.push(ele)
          })
          let foundPosition = newArray.findIndex(ele => ele.innerText.indexOf(comment.attrs.commentText.trim()) !== -1)
          let elementToScroll = newArray[foundPosition]
          elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })

          // Move element to the left option
          elementToScroll.style.zIndex = 1
          elementToScroll.style.transform = `translate(-1.0rem, -0.1ex)`

          this.commentId = id
          this.elevationEffect = true
          // setTimeout(this.elevationEffectTimeOut, 2000)
        }
      }
    },
    onNoCommentSelection () {
      if (this.elevationEffect) {
        this.elevationEffect = false
        document.querySelectorAll('#commentText').forEach(ele => {
          // Move element back
          ele.style.zIndex = 0
          ele.style.transform = `translate(0rem, 0ex)`
        })
      }
    },
    elevationEffectTimeOut () {
      this.elevationEffect = false
      this.commentId = ''
    },
    async addWorkbench () {
      const res = await this.$dialog.prompt({
        title: this.$t('mixed|new_workbench'),
        text: this.$t('actions|enter_workbench_name'),
        textField: {
          // Any addtional props/attrs that will be binded to v-text-field component
          type: 'text',
          outlined: true,
          dense: true,
          class: 'mt-6'
        },
        actions: [{
          text: this.$t('actions|submit'), color: 'primary'
        }]
      })
      if (!res) return
      try {
        const payload = {
          name: res
        }
        await this.addCaseWorkbench({ _id: this.currentCase._id, payload })
      } catch (e) {
        console.error(e, 'error')
      }
    },
    changeRightPanelExpand (value) {
      this.rightPanelExpand = value
    },
    assignCaseDocument () {
      this.addDocumentsToCasePage = true
      // let forwardCompanyDocuments = []
      // let forwardGroupDocuments = []
      // let documents = []
      // let _folders = this.folders
      // let forwardCompanyFolders = []
      // let forwardGroupFolders = []
      // const role = this.verifyUserRole(this.company, this.account)
      // // verifyUserRole => generalMixin
      // if ((role && role !== 'regular') || !this.account.companyId) {
      //   documents = this.documents.filter((doc) => {
      //     const alreadyInUse = this.currentCase.documents.some(d => d._id === doc._id)
      //     return !alreadyInUse
      //   })
      // }
      // if (this.companyDocuments && this.companyDocuments.length) {
      //   forwardCompanyDocuments = this.companyDocuments.filter((doc) => {
      //     const alreadyInUse = this.currentCase.documents.some(d => d._id === doc._id)
      //     return !alreadyInUse
      //   })
      // }
      // if (this.filteredGroupDocuments && this.filteredGroupDocuments.length) {
      //   forwardGroupDocuments = this.filteredGroupDocuments.filter((doc) => {
      //     const alreadyInUse = this.currentCase.documents.some(d => d._id === doc._id)
      //     return !alreadyInUse
      //   })
      // }
      // EventBus.$emit('dialog-documents', {
      //   documents: documents,
      //   companyDocuments: forwardCompanyDocuments,
      //   groupDocuments: forwardGroupDocuments,
      //   fromCaseView: true,
      //   actualCase: this.currentCase,
      //   userRole: role,
      //   company: this.company,
      //   account: this.account,
      //   callback: async (docs) => {
      //     this.updateCase({
      //       _id: this.currentCase._id,
      //       payload: {
      //         payload: {
      //           documents: docs
      //         }
      //       }
      //     })
      //   },
      //   personalFolders: _folders,
      //   companyFolders: forwardCompanyFolders,
      //   groupFolders: forwardGroupFolders
      // })
    },
    async removeCaseDocument (doc) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure') + ' ' + this.$t('warning|document_removed_from_all_workbenches'),
        title: this.$t('common|warning')
      })
      if (!res) return
      this.documentsPanelLoading = true
      try {
        await this.updateCase({
          _id: this.currentCase._id,
          payload: {
            payload: {
              removeDocument: doc._id
            }
          }
        })
      } finally {
        this.documentsPanelLoading = false
      }
    },
    async updateCaseName (currentCaseName) {
      this.updateCase({
        _id: this.currentCase._id,
        payload: {
          payload: { caseName: currentCaseName }
        }
      })
    },
    updateProgress (progressValue) {
      this.updateCase({
        _id: this.currentCase._id,
        payload: {
          payload: { progress: progressValue }
        }
      })
    },
    async updateWorkbenches (actualWbs) {
      for (let i = 0; i < actualWbs.length; i++) {
        const wb = actualWbs[i]
        await this.updateCaseWorkbench({
          _id: this.currentCase._id,
          workbenchId: wb._id,
          payload: {
            name: wb.name
          }
        })
        this.editWorkbenchesPage = false
      }
    },
    async deleteWorkbench (workbench) {
      const res = await this.$dialog.confirm({
        text: `Are you sure you want to delete ${workbench.name} workbench?`,
        title: this.$t('common|warning')
      })
      if (!res) return
      this.loadingDeleteWorkbench = true
      await this.deleteCaseWorkbench({ _id: this.currentCase._id, workbenchId: workbench._id })
      setTimeout(() => {
        this.loadingDeleteWorkbench = false
      }, '1500')
    },
    async uploadDocumentAction (document) {
      const payload = {
        language: document.language,
        file: document.file,
        caseId: this.currentCase._id,
        clientId: this.currentCase.client._id
      }
      return this.uploadDocument(payload)
    },
    async updateQuestionnaire (questionairre) {
      const filesToUpload = questionairre.fields.filter((f) => {
        return f.fieldType === 'file-input'
      })
      const documents = []
      for (let i = 0; i < filesToUpload.length; i++) {
        const fileData = await this.uploadDocumentAction(filesToUpload[i].value[0])
        documents.push(fileData.data._id)
      }
      const payloadFields = questionairre.fields.filter((f) => {
        return f.fieldType !== 'file-input'
      })
      await this.$axios.post(`/updateQuestionnaire/${questionairre._id}`, {
        fields: payloadFields,
        documents
      })
      await this.fetchCase(this.currentCase._id)
    },
    async pinToDashboard (caseFile) {
      const payload = {
        payload: {
          setPin: this.account._id
        }
      }
      try {
        await this.updateCase({
          _id: caseFile._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_pin_to_dashboard_success'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_pin_to_dashboard_fail'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async removePinFromDashboard (caseFile) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning')
      })
      if (!res) return
      if (res) {
        const payload = {
          payload: {
            removePin: this.account._id
          }
        }
        try {
          await this.updateCase({
            _id: caseFile._id,
            payload
          })
          this.addToast({
            title: this.$t('message|case_removal_dashboard_success'),
            color: 'white',
            snackbarColor: 'succsess'
          })
        } catch (e) {
          this.addToast({
            title: this.$t('error|case_removal_dashboard_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    openInfoProgressDialog () {
      this.$refs.InfoProgressDialog.dialog = true
    },
    inUseRequiredDocumentsInQuestionnaire (suggestion) {
      const inUse = this.actualTemplate.questionnaire.fields.find(f => f.fieldKey.toLowerCase() === suggestion.fieldKey.toLowerCase())
      if (inUse) {
        return true
      } else {
        return false
      }
    },
    inUseInQuestionnaire (suggestion) {
      const inUse = this.actualTemplate.questionnaire.fields.find(f => f.fieldKey.toLowerCase() === suggestion.attrs.fieldKey.toLowerCase())
      if (inUse) {
        return true
      } else {
        return false
      }
    },
    inUseInTemplate (questionnaireField) {
      const inUse = this.actualSuggestionsEditorStorage.find(f => f.attrs.fieldKey.toLowerCase() === questionnaireField.fieldKey.toLowerCase())
      if (inUse) {
        return true
      } else {
        return false
      }
    },
    scrollToSuggestion (suggestion) {
      const element = document.getElementById(suggestion.id ? suggestion.id : suggestion.attrs.id)
      if (element && (element.className === 'mention' || element.className === 'mention is-full' || element.className === 'mention view-state is-full')) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
        element.style.backgroundColor = '#D50000'
        setTimeout(() => {
          element.style.backgroundColor = ''
        }, '1000')
      } else if (element && element.className !== 'mention') {
        this.addToast({
          title: this.$t('error|suggestion_not_in_use'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    scrollToQuestionnaireField (suggestion) {
      if (suggestion && suggestion.fieldType && suggestion.fieldType === 'file-input') {
        let transformedSuggestion = {}
        transformedSuggestion.attrs = suggestion
        const usedInQuestionnaire = this.inUseInQuestionnaire(transformedSuggestion)
        const scrollToElement = {
          id: suggestion.id,
          inUse: usedInQuestionnaire,
          tab: 1 // questionnaire
        }
        EventBus.$emit('scroll-to-field-in-questionnaire', scrollToElement)
      }
    },
    async generateCaseModel () {
      const res = await this.$dialog.warning({
        title: this.$t('warning|new_case_model_from_case'),
        text: this.$t('warning|documents_exclusion_if_not_marked'),
        showClose: false,
        width: '750'
      })
      if (!res) return
      this.caseModelData = {
        caseModelName: this.currentCase.caseName,
        description: '',
        category: 'none',
        caseId: this.currentCase._id,
        _case: this.currentCase
      }
      this.$router.push({
        name: 'AddCaseModelMobile',
        params: {
          caseModelData: this.caseModelData,
          fromCase: true
        }
      })
    },
    openCaseTimeline () {
      this.$router.push({
        name: 'CaseTimelineMobile',
        params: {
          currentCase: this.currentCase
        }
      })
    },
    triggerAction (actionType) {
      if (actionType === 'addWorkbench') {
        this.addWorkbench()
      }
      if (actionType === 'editWorkbenchesPage') {
        this.editWorkbenchesPage = true
      }
    },
    getTheRightIcon (actualStatus) {
      switch (actualStatus) {
      case 'To do':
        return this.caseStatuses.todo.icon
      case 'In progress':
        return this.caseStatuses.inprogress.icon
      case 'Done':
        return this.caseStatuses.done.icon
      default:
        return 'mdi-progress-pencil'
      }
    },
    getTranslatedStatus (text) {
      if (text === 'To do') return this.$t('cases|to_do_cases')
      if (text === 'In progress') return this.$t('cases|in_progress')
      if (text === 'Done') return this.$t('cases|finished_cases')
    },
    async exportCase (currentCase) {
      this.loading = true
      await this.createZip()
      this.loading = false
    },
    async downloadDocument (path) {
      const { data } = await this.$axios({
        url: path,
        method: 'GET',
        responseType: 'blob' // important
      })
      return data
    },
    async createZip () {
      let zipNameInput = this.currentCase.caseName
      let zip = new JSZip()
      // let workbenches = zip.folder(`workbenches`)
      let docs = zip.folder(`${this.currentCase.caseName} documents`)
      for (let i = 0; i < this.currentCase.documents.length; i++) {
        // we need to remove .files/ from path. .files is available in backend
        const slicedPath = this.currentCase.documents[i].raw.path.slice(6, this.currentCase.documents[i].raw.path.length)
        const path = `/downloadDocument/${slicedPath}`
        const file = await this.downloadDocument(path)
        docs.file(this.currentCase.documents[i].raw.originalname, new Blob([file]))
      }
      for (let index = 0; index < this.currentCase.workbenches.length; index++) {
        const wb = this.currentCase.workbenches[index]
        let workbenchFolder = zip.folder(`${wb.name}`)
        for (let idx = 0; idx < wb.assignedDocuments.length; idx++) {
          const doc = wb.assignedDocuments[idx]
          const slicedPath = doc.raw.path.slice(6, doc.raw.path.length)
          const path = `/downloadDocument/${slicedPath}`
          const wbFile = await this.downloadDocument(path)
          workbenchFolder.file(doc.raw.originalname, new Blob([wbFile]))
        }
        for (let i = 0; i < wb.templates.length; i++) {
          const template = wb.templates[i]
          const json = template.templateData.json
          this.$refs.Editor.editor.commands.setContent(json)
          const doc = this.$refs.Editor.editor.view.state.doc
          const type = 'docx'
          const templateName = template.name + '.docx'
          const caseExport = true
          const exported = await exportTemplate(doc, templateName, type, caseExport)
          workbenchFolder.file(template.name, exported)
        }
      }
      this.$refs.Editor.editor.destroy()
      zip.generateAsync({ type: 'blob' })
        .then(function (content) {
          // see FileSaver.js
          saveAs(content, zipNameInput)
        }).catch((err) => {
          console.error('createZip', err)
        })
    },
    assignNewClient () {
      this.$refs.NewCaseClientDialog.dialog = true
    },
    async addNewClient (newClient) {
      let clientName
      if (newClient.clientType === 'individual') {
        clientName = newClient.clientData.given_names + ' ' + newClient.clientData.surname
      }
      if (newClient.clientType === 'company') {
        clientName = newClient.clientData.company_name
      }
      const _id = this.currentCase._id
      const payload = {
        newClientId: newClient._id,
        clientName: clientName
      }
      try {
        await this.updateCaseClient({ _id, payload })
      } catch (error) {
        console.error(error)
      }
    },
    assignRequiredCaseDocument () {
      this.addRequiredDocumentsToCasePage = true
    },
    async addCustomField (customFieldInput) {
      const field = {
        id: uuidv4(),
        name: customFieldInput,
        fieldType: 'file-input',
        fieldKey: customFieldInput.split(' ').join('_'),
        key: customFieldInput.split(' ').join('_')
      }
      try {
        await this.updateCase({
          _id: this.currentCase._id,
          payload: {
            payload: {
              requiredCaseDocuments: [field],
              addCaseRequiredDocuments: true
            }
          }
        })
        this.addRequiredDocumentsToCasePage = false
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|adding_required_document_failed'),
          snackbarColor: 'error',
          color: 'white'
        })
      }
    },
    async addDocumentSuggestionsToCase (fields) {
      try {
        await this.updateCase({
          _id: this.currentCase._id,
          payload: {
            payload: {
              requiredCaseDocuments: fields,
              addCaseRequiredDocuments: true
            }
          }
        })
        this.addRequiredDocumentsToCasePage = false
      } catch (e) {
        console.error(e, 'error')
        this.addToast({
          title: this.$t('warning|adding_required_document_failed'),
          snackbarColor: 'error',
          color: 'white'
        })
      }
    },
    async removeRequiredDocument (suggestion) {
      let templateNames = []
      let templateArr = []
      let joinedName
      let res
      for (let i = 0; i < this.currentCase.workbenches.length; i++) {
        this.currentCase.workbenches[i].templates.forEach(temp => {
          const found = temp.questionnaire.fields.find(f => f.id === suggestion.id)
          if (found) {
            templateNames.push(temp.name)
            templateArr.push(temp)
          }
        })
      }
      if (templateNames.length) {
        joinedName = templateNames.join(', ')
      }
      if (joinedName && joinedName.length) {
        res = await this.$dialog.confirm({
          type: 'warning',
          text: this.$t('common|are_you_sure') + ' ' +
          this.$t('fields|field_will_be_also_deleted') + ' ' +
          `${joinedName}` + ' ' +
          this.$t('questionnaire|questionnaire').toLowerCase(),
          title: this.$t('common|warning')
        })
        if (!res) return
        for (let i = 0; i < templateArr.length; i++) {
          const template = templateArr[i]
          try {
            const payload = {
              fieldToBeDeleted: suggestion,
              deleteFieldFromQuestionnaire: true,
              requiredCaseDocumentToBeRemoved: suggestion,
              removeCaseRequiredDocument: true
            }
            const _id = template.questionnaire._id
            const caseId = this.currentCase._id
            const workbench = this.currentCase.workbenches.find(w => {
              const foundTemp = w.templates.find(temp => temp._id === template._id)
              if (foundTemp) return w
            })
            const workbenchId = workbench._id || undefined
            const templateId = template._id
            await this.updateQuestionnaireInCase({ _id, caseId, workbenchId, templateId, payload })
          } catch (e) {
            console.error(e, 'error')
            this.addToast({
              title: this.$t('warning|questionnaire_update_failed'),
              color: 'white',
              snackbarColor: 'error'
            })
          }
        }
      } else {
        res = await this.$dialog.confirm({
          text: this.$t('common|are_you_sure'),
          title: this.$t('common|warning')
        })
        if (!res) return
        try {
          await this.updateCase({
            _id: this.currentCase._id,
            payload: {
              payload: {
                requiredCaseDocumentToBeRemoved: suggestion,
                removeCaseRequiredDocument: true
              }
            }
          })
        } catch (e) {
          console.error(e, 'error')
          this.addToast({
            title: this.$t('warning|workbench_update_failed'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .toolbar {
    position: sticky;
    top: 48px;
    z-index: 1;
  }
  .bottom-element-case-view {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
  .ghost {
    background-color: #d7d7d7;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
  }
  .chip-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center
  }
  .input:focus {
    outline: none !important;
    border:1px solid #234e79;
  }
  .workbenches-panel {
    &.is-expanded {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      z-index: 15;
    }
  }
  .centered-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icon-position {
    position: relative;
  }
  .pin-position {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
  }
  .status-position {
    position: absolute;
    right: 3rem;
    top: 1.5rem;
  }
  .active {
    background-color: #eee;
  }
  .tabs-text {
    text-transform:none !important;
  }
  .fade-enter-active,
  .fade-leave-active {  transition: opacity 0.5s ease;}
  .fade-enter-from,.fade-leave-to {  opacity: 0;}
</style>
