import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'
import { parseCaseStatus } from '@/utils/index'
import SelectToRemoveFrom from '../SelectToRemoveFrom'

export default {
  props: {
    caseData: {
      type: Object,
      default: null
    },
    actualTab: {
      type: Object,
      default: null
    },
    fromClientSection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialogInstance: null,
      sharedWithMembersLenght: 'full'
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    sharedWithMembers () {
      let arr = []
      if (this.caseData.sharedWith.accounts.length) {
        this.caseData.sharedWith.accounts.forEach((acc) => {
          arr.push(acc.accountName)
        })
      }
      if (this.caseData.sharedWith.company.length) {
        this.caseData.sharedWith.company.forEach((c) => {
          arr.push(c.companyData.companyName)
        })
      }
      if (this.caseData.sharedWith.groups.length) {
        this.caseData.sharedWith.groups.forEach((g) => {
          arr.push(g.groupName)
        })
      }
      if (this.sharedWithMembersLenght === 'basic') arr = arr.slice(0, 2)
      return arr
    }
  },
  methods: {
    ...mapActions({
      updateCase: 'cases/updateCase',
      deleteCaseAction: 'cases/deleteCase',
      deleteCompanyCase: 'companyCases/deleteCompanyCase',
      deleteGroupCase: 'groupCases/deleteGroupCase',
      addToast: 'toasts/addToast'
    }),
    routeToCase (_case) {
      if (this.$vuetify.breakpoint.mobile) {
        this.$router.push({
          name: 'CaseViewMobile',
          params: {
            _id: _case._id
          }
        })
      } else {
        this.$router.push(`/cases/${_case._id}`)
      }
    },
    viewCase (_case) {
      this.$router.push({ path: `/cases/${_case._id}` })
    },
    getClientName (client) {
      let _name
      if (client.clientType === 'individual') {
        _name = client.clientData.given_names + ' ' + client.clientData.surname
      }
      if (client.clientType === 'company') {
        _name = client.clientData.company_name
      }
      return _name
    },
    toggleSharedWithMembers (item) {
      if (item === 'basic') this.sharedWithMembersLenght = 'full'
      if (item === 'full') this.sharedWithMembersLenght = 'basic'
    },
    getTranslatedStatus (text) {
      if (text === 'To do') return this.$t('cases|to_do_cases')
      if (text === 'In progress') return this.$t('cases|in_progress')
      if (text === 'Done') return this.$t('cases|finished_cases')
    },
    match (caseData) {
      if (caseData.client && caseData.client.clientAccount) {
        const found = caseData.sharedWith.accounts.find(acc => acc._id === caseData.client.clientAccount._id)
        if (found) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    parseCaseStatus (status) {
      return parseCaseStatus(status)
    },
    async changeStatus (caseFile, status) {
      const payload = {
        payload: {
          status: status.value
        }
      }
      await this.updateCase({
        _id: caseFile._id,
        payload
      })
    },
    async pinToDashboard (caseFile) {
      const payload = {
        payload: {
          setPin: this.account._id
        }
      }
      try {
        await this.updateCase({
          _id: caseFile._id,
          payload
        })
        this.addToast({
          title: this.$t('message|case_pin_to_dashboard_success'),
          color: 'white',
          snackbarColor: 'success'
        })
      } catch (e) {
        this.addToast({
          title: this.$t('error|case_pin_to_dashboard_fail'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async removePinFromDashboard (caseFile) {
      const res = await this.$dialog.confirm({
        text: this.$t('common|are_you_sure'),
        title: this.$t('common|warning')
      })
      if (!res) return
      if (res) {
        const payload = {
          payload: {
            removePin: this.account._id
          }
        }
        try {
          await this.updateCase({
            _id: caseFile._id,
            payload
          })
          this.addToast({
            title: this.$t('message|case_removal_dashboard_success'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch (e) {
          this.addToast({
            title: this.$t('error|case_pin_to_dashboard_fail'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    async deleteCase (caseFile, input) {
      const res = await this.$dialog.confirm({
        text: this.$t('cases|warning_delete_case'),
        title: this.$t('common|warning')
      })
      if (!res) return
      if (this.actualTab && this.actualTab.isMyTab) {
        try {
          await this.deleteCaseAction({ _id: caseFile._id })
        } catch (e) {
          console.error(e, 'e')
        }
      }
      if (this.actualTab && this.actualTab.isCompanyTab) {
        try {
          await this.deleteCompanyCase({ _id: caseFile._id })
        } catch (error) {
          console.error(error, 'e')
        }
      }
      if (this.actualTab && this.actualTab.isGroupTab) {
        try {
          await this.deleteGroupCase({ _id: caseFile._id })
        } catch (error) {
          console.error(error, 'error')
        }
      }
      if (!this.actualTab && this.fromClientSection) {
        try {
          await this.deleteCaseAction({ _id: caseFile._id })
        } catch (e) {
          console.error(e, 'e')
        }
      }
      if (input && input === 'from-case-overview') {
        this.$emit('close-drawer')
        try {
          await this.deleteCaseAction({ _id: caseFile._id })
        } catch (e) {
          console.error(e, 'e')
        }
      }
    },
    async removeCase (caseFile) {
      if (this.actualTab) {
        const res = await this.$dialog.confirm({
          text: `${this.$t('warning|case_removal')} ${this.actualTab.text.bold()}`,
          title: this.$t('common|warning')
        })
        if (res) {
          const payload = {
            payload: {
              name: this.actualTab.text,
              companyId: this.actualTab.company ? this.actualTab.company._id : null,
              groupId: this.actualTab.group ? this.actualTab.group._id : null,
              accId: this.actualTab.isMyTab ? this.account._id : null,
              removeCase: true,
              removeFromShared: this.actualTab.company ? this.actualTab.company._id : this.actualTab.group ? this.actualTab.group._id : this.actualTab.isMyTab ? this.account._id : null,
              isCompany: this.actualTab.isCompanyTab,
              isGroup: this.actualTab.isGroupTab,
              isMyTab: this.actualTab.isMyTab
            }
          }
          try {
            await this.updateCase({
              _id: caseFile._id,
              payload
            })
          } catch (error) {
            console.error(error)
          }
        }
      } else {
        const updateCase = this.updateCase
        const account = this.account
        const company = this.company
        const remove = this.$t('actions|remove')
        const from = this.$t('common|from')
        const select = this.$t('actions|select')
        const required = this.$t('fields|required')
        const myCases = this.$t('cases|my_cases')
        // we are using here a $dialog.show(vue-component)
        // 1. define props of the vue component
        SelectToRemoveFrom.props = {
          caseFileProp: {
            type: Object,
            default: () => ({ caseFile })
          },
          accountProp: {
            type: Object,
            default: () => ({ account })
          },
          companyProp: {
            type: Object,
            default: () => ({ company })
          },
          updateCaseFunction: {
            type: Function,
            default: updateCase
          },
          $t_remove: {
            type: String,
            default: remove
          },
          $t_from: {
            type: String,
            default: from
          },
          $t_select: {
            type: String,
            default: select
          },
          $t_required: {
            type: String,
            default: required
          },
          $t_myCases: {
            type: String,
            default: myCases
          },
          onClick: Function
        }
        // 2. render dialog
        const dialogInstance = await this.$dialog.show(SelectToRemoveFrom, { onClick: this.closeDialogInstance.bind(this.closeDialogInstance) })
        this.dialogInstance = dialogInstance
      }
    },
    closeDialogInstance () {
      this.dialogInstance.close()
    },
    getCaseSharedWithLength (caseFile) {
      return caseFile.sharedWith.accounts.length + caseFile.sharedWith.company.length + caseFile.sharedWith.groups.length
    },
    openOverview () {
      EventBus.$emit('drawer-overview-case', this.caseData)
    },
    getCreator (caseData) {
      if (caseData.createdBy && caseData.createdBy.email) return caseData.createdBy.email
      if (caseData.createdBy && caseData.createdBy.companyData && caseData.createdBy.companyData.companyName) return caseData.createdBy.companyData.companyName
      if (caseData.createdBy && caseData.createdBy.groupName) return caseData.createdBy.groupName
      else return 'not available'
    }
  }
}
